define(['app'], function(app) {
  const exclusiveProducts = function() {
    const component = {};

    component.init = (element) => {
      component.element = element;

      component.element.addEventListener('click', () => {
        window.dataLayer.push({
          event: 'customEvent',
          eventData: {
            eventCategory: 'exclusive products',
            eventAction: 'clicked',
          }
        })
      })

      return component;
    };


    return component;
  };

  return exclusiveProducts;
});
